import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/images/github-inverted.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/images/linkedin.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/images/x-inverted.webp");
import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/landing/next/signup-button.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/navbar-client.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/pixels.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/icons/AppIconWhite.png");
import(/* webpackMode: "eager" */ "/vercel/path0/components/icons/BugpilotLogoBlack.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/components/icons/BugpilotLogoIconWhite.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/button.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@bugpilot+plugin-nextjs@0.19.1_next@14.1.0_react-dom@18.3.0-canary-b36ae8d7a-20231207_react@1_ugpnocyfdhwjjsv7aqbrt45oyu/node_modules/@bugpilot/plugin-nextjs/dist/client/Bugpilot.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@bugpilot+plugin-nextjs@0.19.1_next@14.1.0_react-dom@18.3.0-canary-b36ae8d7a-20231207_react@1_ugpnocyfdhwjjsv7aqbrt45oyu/node_modules/@bugpilot/plugin-nextjs/dist/client/WidgetTrigger.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_react-dom@18.3.0-canary-b36ae8d7a-20231207_react@18.3.0-canary-b36ae8d7a-20231207/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_react-dom@18.3.0-canary-b36ae8d7a-20231207_react@18.3.0-canary-b36ae8d7a-20231207/node_modules/next/dist/client/link.js")