"use client";
import { cn } from "@/lib/utils";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";

export default function NavbarClient() {
  const path = usePathname();

  return (
    <>
      <Link
        href="/"
        className={cn(
          "hidden text-sm underline-offset-4 hover:underline lg:flex",
          {
            underline: path === "/",
          },
        )}
      >
        <span>Product</span>
      </Link>

      <Link
        href="/features"
        className={cn(
          "hidden text-sm underline-offset-4 hover:underline lg:flex",
          {
            underline: path === "/features",
          },
        )}
      >
        <span>Features</span>
      </Link>

      <Link
        href="/pricing"
        className={cn(
          "hidden text-sm underline-offset-4 hover:underline lg:flex",
          {
            underline: path === "/pricing",
          },
        )}
      >
        <span>Pricing</span>
      </Link>
    </>
  );
}
